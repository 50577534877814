import React from "react";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const TableScriptsUser = ({ data }) => {
    const columns = [
        {
            name: "Ações",
            cell: (row) => (
              <>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <i style={{ color: "#808080" }} className="fe fe-edit"></i>&nbsp;
                </button>
      
              </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            with: "200px"
          },
        {
          name: "Nome do Script",
          selector: (row) => [row.name],
          sortable: true,
          maxWidth: "200px"
        },
        {
          name: "Script",
          selector: (row) => [row.value],
          sortable: true,
          maxWidth: "750px"
        },
      ];
    
  const tableData = {
    columns,
    data,
  };
  return (
    <>
      {data.length > 0 && (
        <div className="table-responsive">
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              defaultSortAsc={false}
              striped={true}
              center={true}
              persistTableHead
              // pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      )}
    </>
  );
};

export default TableScriptsUser;
