import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ModalDelete = ({ handleDelete, setShowModal, showModal, item }) => {
    
  return (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação de exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza de que deseja excluir {item}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="secondary"  onClick={handleDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default ModalDelete;
