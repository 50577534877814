import * as S from "./styled";
import Collapse from "@mui/material/Collapse";

const Loading = () => {

  return (
    <S.Wrapper>
      {/* <Collapse in={expanded} timeout="auto">
            <div className="card-body">
              <div className="dimmer active">
                <div className="spinner"></div>
              </div>
            </div>
          </Collapse> */}
      <Collapse
        in={true}
        timeout="auto"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="dimmer active" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="spinner1" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                    </div>
                </div>
        {/* <div className="card-body">
          <div className="dimmer active">
            <div className="spinner"></div>
          </div>
        </div> */}
        {/* <div className="card-body">
                <div className="dimmer active">
                    <div className="lds-hourglass"></div>
                </div>
                </div> */}
      </Collapse>
    </S.Wrapper>
  );
};

export default Loading;
