import React, { useState } from "react";

import { CForm, CCol, CFormLabel, CFormInput } from "@coreui/react";
import { Button, Modal } from "react-bootstrap";

const ModalChangePassword = ({ open, setOpen, handleSubmit, email }) => {
  const [password, setpassword] = useState({
    pass: "",
    confirmPass: "",
  });
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="custom-modal-dialog"
        contentClassName="custom-modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title>Alterar a Senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CForm className="row g-3 needs-validation" noValidate>
            <CCol md={4}>
              <CFormLabel htmlFor="validationCustom01">Email</CFormLabel>
              <CFormInput
                value={email}
                type="email"
                id="validationCustom01"
                disabled
              />
            </CCol>

            <CCol md={3}>
              <CFormLabel htmlFor="validationCustom01">Senha</CFormLabel>
              <CFormInput
                type="password"
                id="validationCustom01"
                value={password.pass}
                onChange={(e) =>
                  setpassword((prevState) => ({
                    ...prevState,
                    pass: e.target.value,
                  }))
                }
              />
            </CCol>

            <CCol md={3}>
              <CFormLabel htmlFor="validationCustom01">
                Confirmar Senha
              </CFormLabel>
              <CFormInput
                type="password"
                id="validationCustom01"
                value={password.confirmPass}
                onChange={(e) =>
                  setpassword((prevState) => ({
                    ...prevState,
                    confirmPass: e.target.value,
                  }))
                }
              />
            </CCol>
          </CForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSubmit(password.pass, password.confirmPass)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalChangePassword;
