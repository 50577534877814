import axios from "axios";
const APIBASE = process.env.REACT_APP_API;
// TOKEN ADMIN
const adminAuth = process.env.REACT_APP_ADMINAUTH;

const customer = (token) => {
  const headers = {
    headers: { "x-access-token": token, "x-auth-token": adminAuth },
  };

  const createCustomer = async (data) => {
    const response = await axios.post(
      `${APIBASE}/customers/create`,
      data,
      headers
    );

    if (response.data) {
      return response.data;
    }
    return {};
  };

  const createCustomerList = async (data) => {
    const response = await axios.post(
      `${APIBASE}/customers/create/list`,
      data,
      headers
    );

    if (response.data) {
      return response.data;
    }
    return {};
  };

  const listAllCustomersWebMaster = async () => {
    const response = await axios.get(`${APIBASE}/customers`, headers);
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const getCustomerByKey = async (key, value) => {
    const payload = {
      key,
      value,
    };
    const response = await axios.post(
      `${APIBASE}/customers/key`,
      payload,
      headers
    );
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const listUserCustomerById = async (url, userId, secretAuth) => {
    console.log(secretAuth);
    const headers = {
      headers: { "x-access-token": secretAuth },
    };

    const response = await axios.get(`${url}/user/${userId}`, headers);
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const updateUserCustomerById = async (url, userId, data, secretAuth) => {
    const headers = {
      headers: { "x-access-token": secretAuth },
    };

    const response = await axios.put(`${url}/user/${userId}`, data, headers);
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const changePassword = async (url, userId, password, secretAuth) => {
    const headers = {
      headers: { "x-access-token": secretAuth },
    };

    const response = await axios.put(
      `${url}/user/password/${userId}`,
      { password },
      headers
    );
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const listLastThirtyCustomersIntegrations = async (
    url,
    userId,
    secretAuth
  ) => {
    const headers = {
      headers: { "x-access-token": secretAuth },
    };

    const response = await axios.get(
      `${url}/customer/integration/${userId}`,
      headers
    );
    if (response.data) {
      return response.data.res;
    }
    return {};
  };

  const deleteUser = async (url, userId) => {
    const response = await axios.delete(`${url}/user/${userId}`);
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const deleteUserWebMaster = async (listId) => {
    const payload = {
      listId: listId,
    };
    const response = await axios.post(
      `${APIBASE}/customers/delete/all`,
      payload,
      headers
    );
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const updateCustomer = async (url, id, customer, secretAuth) => {
    const headers = {
      headers: { "x-access-token": secretAuth },
    };

    const response = await axios.put(
      `${url}/customer/${id}`,
      customer,
      headers
    );
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const deleteCustomer = async (url, id, secretAuth) => {
    const headers = {
      headers: { "x-access-token": secretAuth },
    };

    const response = await axios.delete(`${url}/customer/${id}`, headers);
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const searchByKey = async (url, userId, key, value, secretAuth) => {
    const payload = {
      userId: userId,
      key: key,
      value: value,
    };
    const headers = {
      headers: { "x-access-token": secretAuth },
    };

    const response = await axios.post(
      `${url}/customer/search/key`,
      payload,
      headers
    );
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const listAllCustomer = async (url, userId, secretAuth) => {
    const headers = {
      headers: { "x-access-token": secretAuth },
    };
    const response = await axios.get(`${url}/customer/all/${userId}`, headers);
    if (response.data) {
      return response.data;
    }
    return {};
  };

  const manageLock = async (listUsers, isBlocked) => {
    const payload = {
      listUsers,
      isBlocked,
    };
    const response = await axios.post(
      `${APIBASE}/customers/blocked/all`,
      payload,
      headers
    );

    if (response.data) {
      return response.data;
    }
    return {};
  };


  const createMultiCustomers = async ({ filterListArr }) => {
    const response = await axios.post(
      `${APIBASE}/customers/createMultiCustomers`,
      filterListArr,
      headers
    );

    if (response.data) {
      return response.data;
    }
    return {};
  };

  const getCustomersByField = async (key, list) => {
    const payload = { key, list };
    const response = await axios.post(`${APIBASE}/customers/list/field`,payload,headers);

    if (response.data) {
      return response.data;
    }
    return [];
  };

  return {
    listAllCustomersWebMaster,
    createCustomer,
    createMultiCustomers,
    listUserCustomerById,
    updateUserCustomerById,
    changePassword,
    listLastThirtyCustomersIntegrations,
    deleteUserWebMaster,
    updateCustomer,
    deleteCustomer,
    searchByKey,
    listAllCustomer,
    manageLock,
    getCustomerByKey,
    getCustomersByField
  };
};

export default customer;
