import React from "react";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const Accounts = ({ data, handleAccount, setConfirm, setId }) => {
  const columns = [
    {
      name: "Ações",
      cell: (row) => (
        <>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleAccount(row);
            }}
          >
            <i style={{ color: "#808080" }} className="fe fe-log-in"></i>&nbsp;
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setId(row._id);
              setConfirm(true);
            }}
          >
            <i style={{ color: "#808080" }} class="fe fe-trash"></i>&nbsp;
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Nome Conta",
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => [row.email],
      sortable: true,
    },
    {
      name: "Senha",
      selector: (row) => [row.password],
      sortable: true,
    },
    {
      name: "ID",
      selector: (row) => [row._id],
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data,
  };
  return (
    <>
      {data.length > 0 && (
        <div className="table-responsive">
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              defaultSortAsc={false}
              striped={true}
              center={true}
              persistTableHead
              // pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      )}
    </>
  );
};

export default Accounts;
