import axios from "axios";

const queue = () => {
    const clearQueue = async (url,userId, secretAuth) => {
        const payload = { user_id: userId }
        const headers = {
            headers: { "x-access-token": secretAuth },
        };
        const response = await axios.post(`${url}/queue/clear`,payload, headers);
        if(response.data){
            return response.data;
        }
        return {};
    }

    const listQueue = async (url,userId, secretAuth) => {
        const headers = {
            headers: { "x-access-token": secretAuth },
        };

        const response = await axios.get(`${url}/queue/${userId}`, headers);
        if(!response.data) return;
        const listQueue = response.data;
        const listIds = response.data.map((queue) => queue.client_id);
        return { listIds, listQueue}
    } 

    const customerInQueue = async (url, listIds, secretAuth) => {
        const headers = {
            headers: { "x-access-token": secretAuth },
        };
        const payload = { list: listIds }
        const response = await axios.post(`${url}/customer/customers_in_queue`, payload, headers);
        if(!response.data) return;
        return response.data.res;
    }
    
    const deleteMsgQueue = async (url,id, secretAuth) => {
        const headers = {
            headers: { "x-access-token": secretAuth },
        };
        const response = await axios.delete(`${url}/queue/${id}`, headers);
        if(!response.data) return;
        return response.data;
    } 

    const getCountMsgQueue = async (url, userId, secretAuth) => {
        const headers = {
            headers: { "x-access-token": secretAuth },
        };
        const response = await axios.get(`${url}/queue/count/${userId}`, headers);
        if(!response.data) return;
        return response.data;
    }
    return { clearQueue, listQueue, customerInQueue, deleteMsgQueue, getCountMsgQueue }
}

export default queue;