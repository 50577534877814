import React from "react";

import {
  CForm,
  CCol,
  CFormLabel,
  CFormInput,
} from "@coreui/react";
import { Button, Modal } from "react-bootstrap";

const ModalAccount = ({ account, setAccount ,open, setOpen, saveAccount }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="custom-modal-dialog"
        contentClassName="custom-modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title>Conta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CForm className="row g-3 needs-validation" noValidate>
            <CCol md={3}>
              <CFormLabel htmlFor="validationCustom01">
                Nome da Conta
              </CFormLabel>
              <CFormInput
                value={account.name}
                onChange={(e) =>
                  setAccount((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>

            <CCol md={4}>
              <CFormLabel htmlFor="validationCustom01">Email</CFormLabel>
              <CFormInput
                value={account.email}
                onChange={(e) =>
                  setAccount((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>

            <CCol md={3}>
              <CFormLabel htmlFor="validationCustom01">
                Senha
              </CFormLabel>
              <CFormInput
                value={account.password}
                onChange={(e) =>
                  setAccount((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>

            <CCol md={4}>
              <CFormLabel htmlFor="validationCustom01">
                ID
              </CFormLabel>
              <CFormInput
                value={account._id}
                onChange={(e) =>
                  setAccount((prevState) => ({
                    ...prevState,
                    _id: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>

          </CForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={saveAccount}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalAccount;
