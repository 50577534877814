import React, { useState } from "react";

import { CForm, CCol, CFormLabel, CFormInput } from "@coreui/react";
import { Button, Modal, Form } from "react-bootstrap";

const ModalEnvironments = ({
  open,
  setOpen,
  handleSubmit,
  environment,
  setEnvironment,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="custom-modal-dialog"
        contentClassName="custom-modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Variáveis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CForm className="row g-3 needs-validation" noValidate>
            {Object.entries(environment).map(([key, value]) => (
              <CCol md={12}>
                <CFormLabel htmlFor="validationCustom01">
                  {key}
                  {key === "HINOVA_EXPIRED_BILLET_LINKS"
                    ? " / Hinova Links de boletos expirados"
                    : key === "HINOVA_SGA_DIRECT_IGNORE_OVERDUE_VEHICLES"
                    ? " / Hinova Ignorar veículos atrasado"
                    : key === "REGIONAL_TO_COOPERATIVE"
                    ? " / Regional para cooperativa"
                    : key === "HINOVA_SGA_FILTER_TYPE_SEND_BILLET"
                    ? " / Hinova Filtro tipo enviar boleto"
                    : key === "HINOVA_SGA_PROCESS_ACTIVE_VEHICLES_ONLY"
                    ? " / Hinova Processar apenas veículos ativos"
                    : key === "HINOVA_SGA_DISABLE_REFERENCE_MONTH_PARAM"
                    ? " / Hinova Desativar referência por mês"
                    : key === "HINOVA_SGA_USE_NOTIFICATION_DATE_AS_DAY"
                    ? " / Hinova Data de notificação como dia"
                    : key ===
                      "HINOVA_SGA_USE_NOTIFICATION_DATE_AS_DAY_FOR_OVERDUE"
                    ? " / Hinova Data da notificação como dia para atraso"
                    : key === "HINOVA_SGA_FILTER_OVERDUE_VEHICLES"
                    ? " / Hinova Filtro veículos atrasados"
                    : key ===
                      "HINOVA_SGA_FILTER_BILLETS_WITH_RECURRENT_DESCRIPTION"
                    ? " / Hinova Bilhetes de filtro com descrição recorrente"
                    : key === "BLING_FILTER_ORDERS_WITH_STATUS_ON_CREATE"
                    ? " / Bling filtro Pedidos com status em criação"
                    : key === "BLING_FILTER_ORDERS_WITH_STATUS_ON_UPDATE"
                    ? " / Bling filtro Pedidos com status atualizado"
                    : key === "TRAY_CORP_FILTER_ORDERS_WITH_STATUS_ON_CREATE"
                    ? " / Tray Corp Filtrar pedidos com status em criação"
                    : key === "TRAY_CORP_FILTER_ORDERS_WITH_STATUS_ON_UPDATE"
                    ? " / Tray Corp Filtrar pedidos com status em atualização"
                    : key === "HOTMART_FILTER_ORDERS_WITH_STATUS_ON_CREATE"
                    ? " / Hotmart Filtro pedidos com status em criação"
                    : key === "HOTMART_FILTER_ORDERS_WITH_STATUS_ON_UPDATE"
                    ? " / Hotmart Filtro pedidos com status em atualização"
                    : key === "SDP_FILTER_ORDERS_WITH_STATUS_ON_CREATE"
                    ? " / SDP Filtro de pedidos com status em criação"
                    : key === "SDP_FILTER_ORDERS_WITH_STATUS_ON_UPDATE"
                    ? " / SDP Filtro de pedidos com status em atualização"
                    : key === "SDP_INTEGRATIONS_CHANNELS" &&
                      " / SDP Canais de integrações"}
                </CFormLabel>

                {typeof value === "boolean" ? (
                  <Form.Select
                    value={value ? "true" : "false"}
                    onChange={(e) => {
                      let updatedValue = e.target.value;
                      if (updatedValue === "false" || updatedValue === "true") {
                        updatedValue = updatedValue === "true";
                      }
                      setEnvironment((prevState) => ({
                        ...prevState,
                        [key]: updatedValue,
                      }));
                    }}
                  >
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </Form.Select>
                ) : (
                  <CFormInput
                    id="validationCustom01"
                    value={value}
                    onChange={(e) => {
                      let updatedValue = e.target.value;
                      if (updatedValue === "false" || updatedValue === "true") {
                        updatedValue = updatedValue === "true";
                      }
                      setEnvironment((prevState) => ({
                        ...prevState,
                        [key]: updatedValue,
                      }));
                    }}
                  />
                )}
              </CCol>
            ))}
          </CForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalEnvironments;
