import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmUpdate = ({ title, text, submit, setShowModal, showModal }) => {
    
  return (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {text}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => submit()} >
            Sim
          </Button>
          <Button variant="secondary"  onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default ConfirmUpdate;
