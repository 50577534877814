import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as formvalidation from "../../data/Form/formvalidations/formvalidations";
import { Link } from "react-router-dom";
import { Breadcrumb, Card, Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import Loading from "../Loading";

import ModalIntegrations from "../ModalIntegrations";
import ModalAccount from "../ModalAccount";
import ModalDelete from "../ModalDelete";
import ModalChangePassword from "../ModalChangePassword";
import TableScriptsUser from "../TableScriptsUser";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import "react-multiple-select-dropdown-lite/dist/index.css";
import {
  CForm,
  CCol,
  CFormLabel,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormSwitch,
  CAlert,
} from "@coreui/react";

import customer from "../../service/customer";
import queue from "../../service/queue";

import Accounts from "../Accounts";
import ConfirmUpdate from "../ConfirmUpdate";
import ConfirmQueue from "../ConfirmQueue";
import ModalAddIntegration from "../ModalAddIntegration";
import ModalEnvironments from "../ModalEnvironments";

const User = () => {
  const [custSelected, setCustSelected] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [quickMessage, setQuickMessage] = useState({});
  const [data, setData] = useState([]);
  const [openModalIntegrations, setOpenModalIntegrations] = useState(false);
  const [integrationSelected, setIntegrationSelected] = useState({});
  const [modalDeleteIntegration, setModalDeleteIntegration] = useState(false);
  const [idIntegrationDelete, setIdIntegrationDelete] = useState("");

  const [accounts, setAccounts] = useState([]);
  const [openModalAccount, setOpenModalAccount] = useState(false);
  const [accountSelected, setAccountSelected] = useState({});
  const [modalDeleteAccount, setModalDeleteAccount] = useState(false);
  const [idAccountDelete, setIdAccountDelete] = useState("");

  const [confirmModal, setConfirmModal] = useState(false);

  const [confirmQueue, setconfirmQueue] = useState(false);
  const [modalAddIntegration, setModalAddIntegration] = useState(false);
  const [newIntegration, setNewIntegration] = useState({
    name: "",
    apiKey: "",
    userIntegration: "",
    passIntegration: "",
    baseCodeIntegration: 0,
    timeStamp: 0,
  });

  const [modalChangePass, setModalChangePass] = useState(false);

  const [environment, setEnvironment] = useState({});

  const [modalEnvironment, setModalEnvironment] = useState(false);

  const [isInputError, setInputError] = useState(false);

  const [isLinkBanner, setLinkBanner] = useState("");

  const [isSecretAuth, setSecretAuth] = useState("");

  const token = localStorage.getItem("token");
  const customerRequest = customer(token);
  const queueRequest = queue();

  const secretAuth = localStorage.getItem("secretAuth");
  const apiUrl = localStorage.getItem("apiUrl");
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!apiUrl && !secretAuth) {
      return;
    }
    getCustomer();
  }, [id]);

  const clearQueue = () => {
    setIsLoad(true);
    queueRequest
      .clearQueue(apiUrl, id, secretAuth)
      .then((res) => {
        setToast(true);
        setIsLoad(false);
        setconfirmQueue(false);
      })
      .catch((err) => {
        setToast(false);
        setIsLoad(false);
        console.log(err);
      });
  };

  const handleSubmit = () => {
    setIsLoad(true);
    const payload = custSelected;

    payload.quickMessage = quickMessage;
    payload.integrations = data;
    payload.accounts = accounts;
    payload.environment = environment;

    setConfirmModal(false);

    customerRequest
      .updateUserCustomerById(apiUrl, custSelected._id, payload, secretAuth)
      .then((res) => {
        setToast(true);
        setIsLoad(false);

        setTimeout(() => {
          navigate("/listarClientes");
        }, 2000);
      })
      .catch((err) => {
        console.log("erro:", err);
        setToast(false);
        setIsLoad(false);
      });
  };

  const handleIntegration = (integration) => {
    setIntegrationSelected(integration);
    setOpenModalIntegrations(true);
  };

  const handleAccount = (account) => {
    setAccountSelected(account);
    setOpenModalAccount(true);
  };

  const handleEnv = () => {
    setModalEnvironment(false);
  };

  const saveIntegration = () => {
    const removeIntegration = data.filter((integration) => {
      return integration._id !== integrationSelected._id;
    });
    setData(removeIntegration);

    setData([integrationSelected, ...removeIntegration]);
    setOpenModalIntegrations(false);
  };

  const saveAccount = () => {
    const removeAccount = accounts.filter((account) => {
      return account._id !== accountSelected._id;
    });
    setAccounts(removeAccount);

    setAccounts([accountSelected, ...removeAccount]);
    setOpenModalAccount(false);
  };

  const deleteIntegration = () => {
    const removeIntegration = data.filter(
      (integration) => integration._id !== idIntegrationDelete
    );
    setData(removeIntegration);
    setModalDeleteIntegration(false);
  };

  const deleteAccount = () => {
    const removeAccount = accounts.filter(
      (account) => account._id !== idAccountDelete
    );
    setAccounts(removeAccount);
    setModalDeleteAccount(false);
  };

  const createIntegration = () => {
    console.log(newIntegration);
    const currentDate = new Date();

    const currentTimestamp = currentDate.getTime();
    newIntegration.timeStamp = currentTimestamp;

    setData([newIntegration, ...data]);
    setModalAddIntegration(false);
  };

  const changePassword = (pass, confirmPass) => {
    if (pass !== confirmPass || pass.length <= 4) {
      return;
    }
    setIsLoad(true);
    customerRequest
      .changePassword(apiUrl, custSelected._id, pass, secretAuth)
      .then((res) => {
        setIsLoad(false);
        setToast(true);
        setModalChangePass(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoad(false);
        setToast(false);
      });
  };

  const columns = [
    {
      name: "Ações",
      cell: (row) => (
        <>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleIntegration(row);
            }}
          >
            <i style={{ color: "#808080" }} className="fe fe-edit"></i>&nbsp;
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setIdIntegrationDelete(row._id);
              setModalDeleteIntegration(true);
            }}
          >
            <i style={{ color: "#808080" }} class="fe fe-trash"></i>&nbsp;
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Nome Integração",
      selector: (row) => [row.name],
      sortable: true,
    },
    {
      name: "Usuário Integração",
      selector: (row) => [row.userIntegration],
      sortable: true,
    },
    {
      name: "Senha Integração",
      selector: (row) => [row.passIntegration],
      sortable: true,
    },
    {
      name: "Chave Api",
      selector: (row) => [row.apiKey],
      sortable: true,
    },
    {
      name: "Codígo Base",
      selector: (row) => [row.baseCodeIntegration],
      sortable: true,
    },
    {
      name: "TimeStamp",
      selector: (row) => [row.timeStamp],
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const setToast = (isSuccess) => {
    isSuccess
      ? toast.success(
          <p className="text-white tx-16 mb-0 ">Sucesso!</p>,

          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            transition: Slide,
            autoClose: 2000,
            theme: "colored",
          }
        )
      : toast.error(
          <p className="text-white tx-16 mb-0 ">Erro!</p>,

          {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            transition: Slide,
            autoClose: 2000,
            theme: "colored",
          }
        );
  };

  const validateUrl = (link) => {
    setLinkBanner(link);
    var regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    if (link !== "") {
      if (!regexp.test(link)) {
        setInputError(true);
      } else {
        setInputError(false);

        if (!link.includes("https://") && !link.includes("http://")) {
          setLinkBanner(`https://${link}`);

          return setCustSelected((prevState) => ({
            ...prevState,
            linkBanner: link,
          }));
        }
        setCustSelected((prevState) => ({
          ...prevState,
          linkBanner: link,
        }));
      }
    }
  };

  const getCustomer = () => {
    if (secretAuth)
      customerRequest
        .listUserCustomerById(apiUrl, id, secretAuth)
        .then((cust) => {
          // console.log(cust);
          setEnvironment(cust.environment);
          setUserInfo(cust);
          setCustSelected(cust);
          setQuickMessage(cust.quickMessage);
          setData(cust.integrations);
          setAccounts(cust.accounts);
          setLinkBanner(cust.linkBanner);
          setIsLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  return (
    <div>
      {isLoad && <Loading />}
      <ToastContainer />
      <ModalIntegrations
        open={openModalIntegrations}
        setOpen={setOpenModalIntegrations}
        integration={integrationSelected}
        setIntegration={setIntegrationSelected}
        saveIntegration={saveIntegration}
      />
      <ModalAccount
        account={accountSelected}
        open={openModalAccount}
        setAccount={setAccountSelected}
        setOpen={setOpenModalAccount}
        saveAccount={saveAccount}
      />
      <ModalDelete
        showModal={modalDeleteIntegration}
        handleDelete={deleteIntegration}
        setShowModal={setModalDeleteIntegration}
        item="esta integração"
      />

      <ModalDelete
        showModal={modalDeleteAccount}
        handleDelete={deleteAccount}
        setShowModal={setModalDeleteAccount}
        item="essa conta"
      />
      <ConfirmUpdate
        setShowModal={setConfirmModal}
        showModal={confirmModal}
        submit={handleSubmit}
        title="Confirmação"
        text="Deseja realmente atualizar os dados desse usuário?"
      />

      <ConfirmQueue
        setShowModal={setconfirmQueue}
        showModal={confirmQueue}
        submit={clearQueue}
        title="Confirmação"
        text="Deseja realmente limpar a fila desse usuário?"
      />

      <ModalAddIntegration
        open={modalAddIntegration}
        setOpen={setModalAddIntegration}
        setIntegration={setNewIntegration}
        saveIntegration={createIntegration}
      />

      <ModalChangePassword
        email={custSelected.email}
        handleSubmit={changePassword}
        open={modalChangePass}
        setOpen={setModalChangePass}
      />

      <ModalEnvironments
        environment={environment}
        setEnvironment={setEnvironment}
        open={modalEnvironment}
        handleSubmit={handleEnv}
        setOpen={setModalEnvironment}
      />

      <div className="page-header">
        <div>
          <h1 className="page-title">{userInfo.name}</h1>
          <Breadcrumb className="breadcrumb">
            <Link to="/dashboard">
              <Breadcrumb.Item className="breadcrumb-item">
                Dashboard
              </Breadcrumb.Item>
            </Link>
          </Breadcrumb>
        </div>
      </div>

      {/* <Row>
        <Col md={12}>
          <Card>
            <Card.Header style={{ gap: "10px" }}>
              <h3 className="card-title">Coleções</h3>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <button
                  onClick={getCustomer}
                  className="btn btn-success btn-icon text-white"
                >
                  Clientes
                </button>
                <button
                  onClick={getCustomer}
                  className="btn btn-success btn-icon text-white"
                >
                  Fila
                </button>
                <button
                  onClick={getCustomer}
                  className="btn btn-success btn-icon text-white"
                >
                  Mensagens
                </button>
                <button
                  onClick={getCustomer}
                  className="btn btn-success btn-icon text-white"
                >
                  Lista de Contatos
                </button>
                <button
                  onClick={getCustomer}
                  className="btn btn-success btn-icon text-white"
                >
                  Contatos Bloqueados
                </button>
              </div>
            </Card.Header>
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col md={12} lg={12}>
          <Card>
            <Card.Header style={{ justifyContent: "space-between" }}>
              <h3 className="card-title">Usuário</h3>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  onClick={getCustomer}
                  className="btn btn-success btn-icon text-white"
                >
                  Atualizar Dados
                </Button>

                <Button
                  onClick={() => setconfirmQueue(true)}
                  className="btn btn-warning"
                  variant="warning"
                >
                  Limpar Fila
                </Button>
                <Button
                  onClick={() => setModalChangePass(true)}
                  className="btn btn-warning"
                  variant="danger"
                >
                  Alterar Senha
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <CForm className="row g-3 needs-validation" noValidate>
                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustom01">
                    Nome de Usuário
                  </CFormLabel>
                  <CFormInput
                    value={custSelected.name}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }))
                    }
                    type="text"
                    id="validationCustom01"
                    required
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel>Email</CFormLabel>
                  <CFormInput
                    value={custSelected.email}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={2}>
                  <CFormLabel htmlFor="validationCustom05">Senha</CFormLabel>
                  <CFormInput
                    value={custSelected.password}
                    id="validationCustom05"
                    disabled
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">ID</CFormLabel>
                  <CFormInput
                    value={custSelected._id}
                    id="validationCustom05"
                    disabled
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="formSwitchCheckChecked">
                    Conexão do Whatsapp
                  </CFormLabel>
                  <CFormInput
                    id="formSwitchCheckChecked"
                    value={custSelected.connection_status}
                    disabled
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="formSwitchCheckChecked">
                    Atualização de Conexão
                  </CFormLabel>
                  <CFormInput
                    id="formSwitchCheckChecked"
                    value={custSelected.connectionUpdateDate}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        connectionUpdateDate: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="formSwitchCheckChecked">
                    Sistema
                  </CFormLabel>

                  <Form.Select
                    value={custSelected.isBlocked ? "true" : "false"}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        isBlocked: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Bloqueado</option>
                    <option value="false">Desbloqueado</option>
                  </Form.Select>
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="formSwitchCheckChecked">
                    Status da Fila
                  </CFormLabel>

                  <Form.Select
                    value={custSelected.statusQueue ? "true" : "false"}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        statusQueue: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Ativa</option>
                    <option value="false">Desativada</option>
                  </Form.Select>
                </CCol>
                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom03">
                    Inicio de Envio
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom03"
                    value={custSelected.startQueue}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        startQueue: e.target.value,
                      }))
                    }
                    required
                  />
                </CCol>
                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Final de Envio
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.endQueue}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        endQueue: e.target.value,
                      }))
                    }
                  />
                </CCol>
                <CCol md={2}>
                  <CFormLabel htmlFor="validationCustom05">
                    Intervalo
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={quickMessage.wait}
                    onChange={(e) =>
                      setQuickMessage((prevState) => ({
                        ...prevState,
                        wait: e.target.value,
                      }))
                    }
                  />
                </CCol>
                <CCol md={2}>
                  <CFormLabel htmlFor="validationCustom05">Pacote</CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={quickMessage.pack}
                    onChange={(e) =>
                      setQuickMessage((prevState) => ({
                        ...prevState,
                        pack: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">Delay</CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={quickMessage.delay}
                    onChange={(e) =>
                      setQuickMessage((prevState) => ({
                        ...prevState,
                        delay: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Quick Message Ativo
                  </CFormLabel>
                  <Form.Select
                    value={quickMessage.active ? "true" : "false"}
                    onChange={(e) =>
                      setQuickMessage((prevState) => ({
                        ...prevState,
                        active: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </Form.Select>
                </CCol>

                <CCol md={2}>
                  <CFormLabel htmlFor="validationCustom05">Contador</CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={quickMessage.counter}
                    onChange={(e) =>
                      setQuickMessage((prevState) => ({
                        ...prevState,
                        counter: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Limite de Mensagens
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.messageLimit}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        messageLimit: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Mensagens Enviada
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.messageSent}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        messageSent: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={2}>
                  <CFormLabel htmlFor="validationCustom05">Order</CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.orders}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        orders: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={2}>
                  <CFormLabel htmlFor="validationCustom05">
                    Fim de Semana
                  </CFormLabel>
                  <Form.Select
                    value={custSelected.weekend_active ? "true" : "false"}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        weekend_active: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </Form.Select>
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Mensagem Boas Vindas
                  </CFormLabel>
                  <Form.Select
                    value={
                      custSelected.welcome_message_active ? "true" : "false"
                    }
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        welcome_message_active: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </Form.Select>
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Mensagem Aniversário
                  </CFormLabel>
                  <Form.Select
                    value={
                      custSelected.birthday_message_active ? "true" : "false"
                    }
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        birthday_message_active: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </Form.Select>
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Link Cadastro Cartões
                  </CFormLabel>
                  <Form.Select
                    value={custSelected.registrationCards ? "true" : "false"}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        registrationCards: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </Form.Select>
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Intervalo de Autenticação
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.delaySessionAccount}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        delaySessionAccount: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Mostrar Banner
                  </CFormLabel>
                  <Form.Select
                    value={custSelected.showBanner ? "true" : "false"}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        showBanner: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </Form.Select>
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustom01">
                    Link do Banner
                  </CFormLabel>
                  <CFormInput
                    value={isLinkBanner}
                    onChange={(e) => validateUrl(e.target.value)}
                    type="text"
                    id="validationCustom01"
                  />
                  <CAlert
                    color="danger"
                    dismissible
                    visible={isInputError}
                    onClose={() => setInputError(false)}
                  >
                    Insira uma url válida!
                  </CAlert>
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustom01">
                    Secret Auth
                  </CFormLabel>
                  <CFormInput disabled value={secretAuth} />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Permitir Duplicado
                  </CFormLabel>

                  <Form.Select
                    value={custSelected.allowDuplicates ? "true" : "false"}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        allowDuplicates: e.target.value == "true",
                      }))
                    }
                  >
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </Form.Select>
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustom05">
                    Dispositivo ID
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.device_id}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        device_id: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">
                    Cliente ID
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.client_id}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        client_id: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={3}>
                  <CFormLabel htmlFor="validationCustom05">Negócio</CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.business}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        business: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={2}>
                  <CFormLabel htmlFor="validationCustom05">Api</CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.api}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        api: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustom05">Api Url</CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.apiUrl}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        apiUrl: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustom05">Hub Url</CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.hubUrl}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        hubUrl: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustom05">
                    Callback Url
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.callbackUrl}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        callbackUrl: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={4}>
                  <CFormLabel htmlFor="validationCustom05">
                    Url do Servidor
                  </CFormLabel>
                  <CFormInput
                    id="validationCustom05"
                    value={custSelected.serverApiUrl}
                    onChange={(e) =>
                      setCustSelected((prevState) => ({
                        ...prevState,
                        serverApiUrl: e.target.value,
                      }))
                    }
                  />
                </CCol>

                <CCol md={12} style={{ marginTop: "30px" }}>
                  <Button onClick={() => setModalEnvironment(true)}>
                    Configurar Variaveis Hub
                  </Button>
                </CCol>

                <CCol md={12}>
                  {data && (
                    <div className="table-responsive">
                      <DataTableExtensions {...tableData}>
                        <DataTable
                          columns={columns}
                          data={data}
                          noHeader
                          defaultSortField="id"
                          defaultSortAsc={false}
                          striped={true}
                          center={true}
                          persistTableHead
                          highlightOnHover
                        />
                      </DataTableExtensions>
                    </div>
                  )}
                  <Button onClick={() => setModalAddIntegration(true)}>
                    <span>
                      <i className="fe fe-plus"></i>&nbsp;
                    </span>
                    Add Integração
                  </Button>
                </CCol>

                <CCol md={12}>
                  {accounts && (
                    <Accounts
                      data={accounts}
                      handleAccount={handleAccount}
                      setConfirm={setModalDeleteAccount}
                      setId={setIdAccountDelete}
                    />
                  )}
                </CCol>

                <CCol md={12}>
                  {custSelected?.scripts && (
                    <TableScriptsUser data={custSelected?.scripts} />
                  )}
                </CCol>

                <CCol xs={12} style={{ marginTop: "40px" }}>
                  <CButton
                    color="primary"
                    onClick={() => setConfirmModal(true)}
                  >
                    Salvar
                  </CButton>
                </CCol>
              </CForm>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default User;
