import React from "react";

import {
  CForm,
  CCol,
  CFormLabel,
  CFormInput,
} from "@coreui/react";
import { Button, Modal } from "react-bootstrap";

const ModalIntegrations = ({ integration, setIntegration ,open, setOpen, saveIntegration }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="custom-modal-dialog"
        contentClassName="custom-modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title>Integração</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CForm className="row g-3 needs-validation" noValidate>
            <CCol md={3}>
              <CFormLabel htmlFor="validationCustom01">
                Nome de Integração
              </CFormLabel>
              <CFormInput
                value={integration.name}
                onChange={(e) =>
                  setIntegration((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>

            <CCol md={4}>
              <CFormLabel htmlFor="validationCustom01">Chave Api</CFormLabel>
              <CFormInput
                value={integration.apiKey}
                onChange={(e) =>
                  setIntegration((prevState) => ({
                    ...prevState,
                    apiKey: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>

            <CCol md={3}>
              <CFormLabel htmlFor="validationCustom01">
                Usuário de Integração
              </CFormLabel>
              <CFormInput
                value={integration.userIntegration}
                onChange={(e) =>
                  setIntegration((prevState) => ({
                    ...prevState,
                    userIntegration: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>

            <CCol md={3}>
              <CFormLabel htmlFor="validationCustom01">
                Senha de Integração
              </CFormLabel>
              <CFormInput
                value={integration.passIntegration}
                onChange={(e) =>
                  setIntegration((prevState) => ({
                    ...prevState,
                    passIntegration: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>

            <CCol md={3}>
              <CFormLabel htmlFor="validationCustom01">Código Base</CFormLabel>
              <CFormInput
                value={integration.baseCodeIntegration}
                onChange={(e) =>
                  setIntegration((prevState) => ({
                    ...prevState,
                    baseCodeIntegration: e.target.value,
                  }))
                }
                type="text"
                id="validationCustom01"
              />
            </CCol>
          </CForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={saveIntegration}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalIntegrations;
